import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { trpc } from "../Utility/trpc";
import { AUTH_TOKEN_KEY } from "~/utils/api";
import Script from "next/script";
import { UserAccountDoesNotExist } from "@withjuly/fabric";
import { AiLoader, useToast } from "@withjuly/solis";
import { JulyLogo, Warning } from "@withjuly/julycons";
import { getApiError } from "@withjuly/frontend-common";
import * as Dialog from "@radix-ui/react-dialog";

interface GoogleButtonProps {
	width?: number;
	subscribe?: boolean;
	referrer?: string;
	isSignIn?: boolean;
}

export const GoogleButton: React.FC<GoogleButtonProps> = ({
	width,
	subscribe,
	referrer,
	isSignIn,
}) => {
	const buttonParentRef = useRef<HTMLDivElement>(null);
	const [isGoogleLoaded, setIsGoogleLoaded] = useState(() => false);
	const [isAccountLoading, setIsAccountLoading] = useState(() => false);
	const router = useRouter();
	const { toast } = useToast();
	const signUpWithGoogle =
		trpc.user.onboarding.signinOrsignupWithGoogle.useMutation({
			onSuccess: (token) => {
				if (token.type === "provisional") {
					const params = new URLSearchParams();
					params.set("next", router.query.next as string);
					params.set("token", token.token as string);
					router.push("/auth/otp?" + params.toString());
				} else {
					localStorage.setItem(AUTH_TOKEN_KEY, token.token);
					router.push("/creator/mediakit");
				}
			},
			onError: (error) => {
				setIsAccountLoading(() => false);
				const apiError = getApiError(error);
				if (apiError && apiError.name === UserAccountDoesNotExist.name) {
					toast({
						title: apiError.message,
						description: apiError.description,
						status: "danger",
						icon: <Warning />,
					});
				}
			},
		});

	const callback = (e: unknown) => {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const token = (e as any).credential;
		setIsAccountLoading(() => true);
		signUpWithGoogle.mutate({ token, subscribe, referrer, isSignIn });
	};

	useEffect(() => {
		if (buttonParentRef.current && isGoogleLoaded) {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			(window as any).google.accounts.id.renderButton(buttonParentRef.current, {
				type: "standard",
				theme: "outline",
				size: "large",
				text: isSignIn ? "signin_with" : "signup_with",
				shape: "rectangular",
				logo_alignment: "left",
				width: width,
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [buttonParentRef.current, isGoogleLoaded]);

	return (
		<>
			<Script
				src="https://accounts.google.com/gsi/client"
				async
				onReady={() => {
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					(window as any).google.accounts.id.initialize({
						client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? "",
						callback,
						context: "signup",
						auto_select: false,
					});

					setIsGoogleLoaded(() => true);
				}}
			></Script>
			<div ref={buttonParentRef} className="h-[44px] w-full cursor-pointer" />
			<AccountLoadingModal isOpen={isAccountLoading} isSignIn={isSignIn} />
		</>
	);
};

interface AccountLoadingModalProps {
	isOpen: boolean;
	isSignIn?: boolean;
}

const AccountLoadingModal: React.FC<AccountLoadingModalProps> = ({
	isOpen,
	isSignIn,
}) => {
	return (
		<Dialog.Root open={isOpen} modal>
			<Dialog.Portal>
				<Dialog.Overlay className="fixed inset-0 top-16 z-10 bg-black/50 lg:top-0" />
				<Dialog.Content className="fixed inset-0 z-20 flex h-screen w-screen items-center justify-center">
					<div className="flex flex-col items-center justify-start gap-[97px] rounded-xl border border-gray-500 bg-gray-800 px-8 py-8 pb-[153px] md:px-16">
						<JulyLogo className="h-6 w-[56px]" />
						<div className="flex flex-col items-center justify-center gap-4">
							<h5 className="text-[20px]">
								{isSignIn ? "Signing into July" : "Creating July Account"}
							</h5>
							<AiLoader className="w-[256px] md:w-[352px]" />
						</div>
					</div>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};
