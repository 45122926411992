import { SignInEmailSchema } from "@withjuly/fabric";
import { FormProvider } from "react-hook-form";
import { TextInput } from "~/components/Input";
import { ReactElement, useState } from "react";
import { NextPageWithLayout } from "~/utils/next";
import { Button, Heading, spacing } from "@withjuly/solis";
import { trpc } from "~/components/Utility/trpc";
import { CheckYourInbox } from "~/components/Signup/CheckYourInbox";
import { AuthLayout } from "~/layout/AuthLayout";
import { useZodForm } from "~/utils/hooks/zod-form";
import Link from "next/link";
import { useRouter } from "next/router";
import { GoogleButton } from "~/components/Onboarding/GoogleButton";
import { AUTH_TOKEN_KEY } from "~/utils/api";

const SignInPage: NextPageWithLayout = () => {
	const [sentToEmail, setSentToEmail] = useState<string>();
	const sendSignInEmail = trpc.user.sendSignInEmail.useMutation();
	const router = useRouter();
	const { next, bwb } = router.query;

	const form = useZodForm({
		schema: SignInEmailSchema,
		values: {
			email: "",
			isOnboarding: false,
			next: next as string,
			bwb: bwb as string,
		},
		submit: async (data, { onFormError }) => {
			sendSignInEmail.mutate(data, {
				onSuccess: (token) => {
					if (token) {
						localStorage.setItem(AUTH_TOKEN_KEY, token);
						router.push("/creator/mediakit");
					} else {
						setSentToEmail(data.email);
					}
				},
				onError: (e) => onFormError(e.data),
			});
		},
	});

	return (
		<div className="flex h-full flex-col justify-center gap-4 px-4">
			{sentToEmail ? (
				<CheckYourInbox email={sentToEmail} />
			) : (
				<>
					<div className="flex h-full w-full flex-col items-center justify-center">
						<div className="flex h-full w-full max-w-[352px] flex-col items-center justify-center gap-6">
							<Heading variant="h4">Sign in to July</Heading>
							<GoogleButton width={352} isSignIn />
							<div className="flex w-full items-center justify-center gap-2">
								<div className="h-px w-full bg-gray-400" />
								<p className="text-body-sm w-fit whitespace-nowrap text-gray-200">
									Or continue with email
								</p>
								<div className="h-px w-full bg-gray-400" />
							</div>
							<FormProvider {...form}>
								<form
									onSubmit={form.onSubmit}
									style={{ width: "100%", paddingTop: spacing[50] }}
								>
									<div className="flex h-full w-full flex-col gap-6">
										<div className="flex w-full flex-col gap-4">
											<TextInput
												name="email"
												type="email"
												placeholder="Enter Email Address"
											/>
										</div>

										<Button
											isLoading={sendSignInEmail.isLoading}
											size="huge"
											type="submit"
											full
										>
											Sign in
										</Button>
									</div>
								</form>
							</FormProvider>
							<div className="flex w-full items-center justify-center gap-1">
								<p className="text-gray-200">Don&apos;t have an account?</p>
								<Link
									href={`/signup${next ? "?next=" + next : ""}`}
									className="underline"
								>
									<p className="text-gray-100">Sign up -&gt;</p>
								</Link>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

SignInPage.getLayout = (page: ReactElement) => {
	return <AuthLayout>{page}</AuthLayout>;
};

export default SignInPage;
