import { useToast, Button } from "@withjuly/solisv2";
import { Envelope } from "@withjuly/julycons/bold";
import { trpc } from "../Utility/trpc";
import { useEffect, useState } from "react";

export interface CheckYourInboxProps {
	email: string;
	isOnboarding?: boolean;
}

export const CheckYourInbox: React.FC<CheckYourInboxProps> = ({
	email,
	isOnboarding,
}) => {
	const sendSignInEmail = trpc.user.sendSignInEmail.useMutation();
	const [enabled, setEnabled] = useState(false);
	const { toast } = useToast();

	useEffect(() => {
		const timeout = setTimeout(() => {
			setEnabled(true);
		}, 10_000);

		return () => {
			clearTimeout(timeout);
		};
	}, [enabled]);

	return (
		<div className="flex h-full w-full flex-col items-center justify-center">
			<div className="flex h-full w-full flex-col items-center justify-center gap-6">
				<div className="flex flex-col items-center justify-center gap-6">
					<div className="bg-sky-alpha-4 w-fit rounded-full p-4">
						<Envelope className="text-brand h-6 w-6" />
					</div>

					<div className="flex w-full flex-col gap-2 text-center">
						<p className="text-header-xl font-repro">Check your email</p>
						<p className="text-paragraph-sm text-text-secondary font-repro">
							We sent a sign in link to{" "}
							<span className="text-button-sm font-repro text-text-primary">
								{email}
							</span>
						</p>
					</div>
				</div>
			</div>
			<div className="flex min-h-[88px] items-center gap-2">
				<p className="text-paragraph-sm text-text-tertiary font-repro">
					Don’t see it?
				</p>
				<Button
					variant="blank"
					size="sm"
					disabled={!enabled}
					onClick={() => {
						if (enabled) {
							sendSignInEmail.mutate(
								{ email, isOnboarding },
								{
									onSuccess: () =>
										toast({
											title: "Email sent",
											description: "We resent your sign in link.",
										}),
								},
							);
							setEnabled(false);
						}
					}}
				>
					Resend email
				</Button>
			</div>
		</div>
	);
};
